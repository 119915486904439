import React , {  useRef } from 'react' //
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'

import { useState, useEffect } from 'react'

import { Button } from "../button/Button";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import '../../App.css';

import { createTheme, ThemeProvider } from "@mui/material/styles";

let initial_list = [
  {
    id: 0,
    maxBasePoint: 0,
    wordlist: [],
    wordmin: 1,
    answer: "no answer",
    indice_1: "no",
    indice_2: '/miniatures/1.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 0,
    done: 0,
    polypoints: "0",
  },
  {
    id: 1,
    maxBasePoint: 5,
    wordlist: ['river', 'dragon','dragons','mekong','mékong','rivers','riviere'],
    wordmin: 2,
    answer: "river dragons",
    indice_1: "Dans ce premier jeu de Roberto Fraga, les joueurs incarnent des asiatiques qui doivent rapporter du riz sur des planches instables !",
    indice_2: '/miniatures/1.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "45,45 103,45 114,119 45,130",
  },
  {
    id: 2,
    maxBasePoint: 5,
    wordlist: ['carrom', 'carom','karom','karrom'],
    wordmin: 1,
    answer: "carrom",
    indice_1: "Ce jeu d'adresse est couramment appellé le Billard Indien",
    indice_2: '/miniatures/2.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "103,45 115,119 276,94 269,45",
    
  },
  {
    id: 3,
    maxBasePoint: 5,
    wordlist: ['race', 'galaxy', 'galaxie','for','races'],
    wordmin: 2,
    answer: "race for the galaxy",
    indice_1: "Jeu de Thomas Lehmann qui est une véritable course pour la construction d'un empire galactique constitué de mondes et de développements technologiques.",
    indice_2: '/miniatures/3.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "272,45 278,93 441,68 437,45",
  },
  {
    id: 4,
    maxBasePoint: 5,
    wordlist: ['carcassonne','carcasonne','carcasone','carcassone','karcassone','karcassonne'],
    wordmin: 1,
    answer: "carcassonne",
    indice_1: "Le Spiel des Jahres 2001, est Le jeu qui a introduit le Meeple dans la sphère ludique, son nom est celui d'une célèbre ville médievale française",
    indice_2: '/miniatures/4.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "45,133 114,123 138,285 45,299",
  }, {
    id: 5,
    maxBasePoint: 3,
    wordlist: ['abalone', 'abbalonne', 'abballone', 'abballonne', 'aballone', 'abalonne','abbalone'],
    wordmin: 1,
    answer: "abalone",
    indice_1: "Ce jeu primé As d'or Jeu de l'année 1989 propose aux joueurs de déplacer leurs billes sur un plateau hexagonal pour éjecter celles de l'adversaire",
    indice_2: '/miniatures/5.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "115,123 141,285 303,258 277,96",
  }, {
    id: 6,
    maxBasePoint: 3,
    wordlist: ['dixit', 'dicxit','dixyt','dyxit','dixite'],
    wordmin: 1,
    answer: "dixit",
    indice_1: "Créé en 2008 par Jean Louis Roubira, ce jeu pour rêveur deviendra en juin 2010 le premier jeu de l'année en Allemagne dont l'auteur et l'éditeur sont français ",
    indice_2: '/miniatures/6.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "440,70 471,235 611,211 611,44",
  },
  { //tile cité des jeux
    id: 7,
    maxBasePoint: 0,
    wordlist: [],
    wordmin: 1,
    answer: "no answer",
    indice_1: "no tile",
    indice_2: '/miniatures/1.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 0,
    done: 0,
    polypoints: "282,96 442,71 467,233 307,258",
  }, {
    id: 8,
    maxBasePoint: 5,
    wordlist: ['mile', 'mille', 'milles', 'borne', 'bornes','miles','1000'],
    wordmin: 2,
    answer: "mille bornes",
    indice_1: "Grand Classique , le nom de ce jeu inventé en 1954 par Edmond Dujardin s'inspire de la mythique et ancienne route Nationale 7 en France ",
    indice_2: '/miniatures/8.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "45,302 45,470 164,451 142,288",
  }, {
    id: 9,
    maxBasePoint: 3,
    wordlist: ['takenoko', 'taquenoko','takenoquo','taquenauko','takenauko'],
    wordmin: 1,
    answer: "takenoko",
    indice_1: "Dans ce jeu d'Antoine Bauza apparu en 2011 et As d'or jeu de l'année 2012, les joueurs ont pour mission le bien-être d'un Panda",
    indice_2: '/miniatures/9.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "141,289 167,451 328,423 304,260",
  }, {
    id: 10,
    maxBasePoint: 3,
    wordlist: ['hippos','hippo','gloutons','glouton','hypo','hyppo'],
    wordmin: 2,
    answer: "hippos gloutons",
    indice_1: "Ce jeu contenant quatre Hippopotames affamés est édité pour la première fois en 1966 par Milton Bradlley (MB) et fait toujours partie des rayons des magasins !",
    indice_2: '/miniatures/10.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "306,260 331,423 494,398 468,236",
  }, {
    id: 11,
    maxBasePoint: 5,
    wordlist: ['imagine','imagin','image-in'],
    wordmin: 1,
    answer: "imagine",
    indice_1: "Un jeu d’origine japonaise où l’on fait deviner des « énigmes » en utilisant des cartes transparentes avec des symboles ou des formes.",
    indice_2: '/miniatures/11.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "471,235 496,397 612,379 612,213",
  }, {
    id: 12,
    maxBasePoint: 5,
    wordlist: ['splendor','splendoor'],
    wordmin: 1,
    answer: "splendor",
    indice_1: "La maison d'édition Space Cow Boys se démarque encore avec ce jeu de cartes pourvu de splendides jetons de poker représentant des pierres précieuses",
    indice_2: '/miniatures/12.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "45,471 45,638 191,614 164,453",
  }, {
    id: 13,
    maxBasePoint: 3,
    wordlist: ['quoridor','quorridor','koridor','quoridore','korridor'],
    wordmin: 1,
    answer: "quoridor",
    indice_1: "Le jeu en bois pour 2, 3 ou 4 joueurs, le plus labyrinthique de l'éditeur Gigamic ! ",
    indice_2: '/miniatures/13.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "157,454 195,613 356,589 330,427",
  }, {
    id: 14,
    maxBasePoint: 3,
    wordlist: ['docteur','maboul','maboule','mabul','dr'],
    wordmin: 1,
    answer: "docteur maboul",
    indice_1: "Crée par John Spinello en 1964 alors étudiant, édité dès 1965 par Milton Bradley (MB) au USA, ce jeu se déroulant au dessus d'une table d'opération chirugicale apparait en France en 1978",
    indice_2: '/miniatures/14.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "333,427 356,587 519,563 494,399",
  }, {
    id: 15,
    maxBasePoint: 3,
    wordlist: ['bilboquet','bilboket','billboquet','billboket','bilbokai'],
    wordmin: 1,
    answer: "bilboquet",
    indice_1: "Jeu d'adresse composé d’une tige souvent en bois reliée par une cordelette à une boule percée",
    indice_2: '/miniatures/15.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "45,640 192,616 218,785 45,806",
  }, {
    id: 16,
    maxBasePoint: 3,
    wordlist: ['mikado','mykado','miquado','mikaddo'],
    wordmin: 1,
    answer: "mikado",
    indice_1: "Un classique des jeux de voyage, on en mangerait ! ",
    indice_2: '/miniatures/16.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "194,617 218,779 381,754 355,591",
  }, {
    id: 17,
    maxBasePoint: 3,
    wordlist: ['marrakech','marakech','marrakeche','marakeche'],
    wordmin: 1,
    answer: "marrakech",
    indice_1: "Avec Assam, disposez au mieux vos tapis dans le souk pour gagner le plus de dirham . Après avoir gagné un as D'or jeu de l'année en 2008, ce jeu gagne un relooking en 2023. ",
    indice_2: '/miniatures/17.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "357,591 384,753 548,728 520,566",
  }, {
    id: 18,
    maxBasePoint: 5,
    wordlist: ['monopoly','monopoli','monoppoli','monopolli','monopolly'],
    wordmin: 1,
    answer: "monopoly",
    indice_1: "Probablement un des jeux de société les plus iconiques. Ironie de l'Histoire des jeux : celui-ci est parfois perçu comme une apologie du capitalisme, mais il a été originellement conçu comme une dénonciation de celui-ci. ",
    indice_2: '/miniatures/18.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "523,565 548,727 612,719 612,551",
  }, {
    id: 19,
    maxBasePoint: 5,
    wordlist: ["can't",'stop','cant','can'],
    wordmin: 2,
    answer: "can't stop",
    indice_1: "Dans ce jeu de stop ou encore créé en 1980 par Sid Sackson, les joueurs doivent faire progresser leurs grimpeurs vers le sommet",
    indice_2: '/miniatures/19.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "45,809 52,807 77,971 45,975",
  }, {
    id: 20,
    maxBasePoint: 5,
    wordlist: ['deep','sea','adventure','aventure','deap'],
    wordmin: 2,
    answer: "deep sea adventure",
    indice_1: "Certains éditeurs japonais nous ont habitués à des propositions ludiques dans de petits écrins : c'est encore le cas ici avec un jeu profond d'exploration marine, basé sur la prise de risque et le stop ou encore. ",
    indice_2: '/miniatures/20.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "495,400 612,383 615,547 522,561",
  }, {
    id: 21,
    maxBasePoint: 3,
    wordlist: ['mysterium','misterium','misteryum','mysteryum'],
    wordmin: 1,
    answer: "mysterium",
    indice_1: "Quand un fantôme essaie de nous faire comprendre les circonstances de sa mort, mais qu'il ne peut nous faire parvenir que des visions....Edité par Libellud, avec des cartes proches du jeu Dixit. ",
    indice_2: '/miniatures/21.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "54,807 80,970 242,945 218,783",
  }, {
    id: 22,
    maxBasePoint: 3,
    wordlist: ['bang','wanted','bullet','bang !'],
    wordmin: 1,
    answer: "bang",
    indice_1: "Initialement appelé Wanted, ce jeu de cartes à élimination peut faire de vous un adjoint du sheriff, un renégat ou un hors la loi, mais ce rôle n'est connu que de vous, à moins que vous ne soyez le Sheriff en personne...",
    indice_2: '/miniatures/22.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "220,781 245,944 407,919 382,756",
  }, {
    id: 23,
    maxBasePoint: 3,
    wordlist: ['chifoumi','chyfoumi','shifumy','pierre','feuille','ciseau'],
    wordmin: 1,
    answer: "chifoumi",
    indice_1: "Pour décider qui sort les poubelles ou qui aura droit de manger la dernière tranche de saucisson, ce jeu de mains sans aucun matériel est souvent pratique. Puits interdit. ",
    indice_2: '/miniatures/23.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "385,756 547,730 570,892 410,918",
  }, {
    id: 24,
    maxBasePoint: 5,
    wordlist: ['king','tokyo','tokio','kings'],
    wordmin: 2,
    answer: "king of tokyo",
    indice_1: "Godzilla, ça vous parle ? Des créatures immenses se battent les unes contre les autres pour détruire la ville, et en devenir le roi ! Gare aux baffes !",
    indice_2: '/miniatures/24.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "549,729 611,719 612,887 570,891",
  }, {
    id: 25,
    maxBasePoint: 5,
    wordlist: ['donjons','dragons','donjon','dragon'],
    wordmin: 2,
    answer: "donjons et dragons",
    indice_1: "Là, on est dans le jeu de rôle, et pas n'importe lequel : c'est tout simplement le systeme le plus utilisé à travers le monde pour vivre des aventures dans un univers médieval fantastique. Adapté au cinéma en 2023",
    indice_2: '/miniatures/25.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "45,975 81,974 103,1135 45,1145",
  }, {
    id: 26,
    maxBasePoint: 3,
    wordlist: ['aventurier','aventuriers','rail','rails'],
    wordmin: 2,
    answer: "les aventuriers du rail",
    indice_1: "La première édition proposait aux joueurs de relier par voie ferroviaire des villes entre elles sur la carte des Etats-Unis, la seconde en Europe... En 2023, l'éditeur Days of Wonder peut être fier d'avoir édité 24 versions du jeu et extensions réunies !!! ",
    indice_2: '/miniatures/26.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "80,972 244,946 268,1110 104,1135",
  }, {
    id: 27,
    maxBasePoint: 3,
    wordlist: ['unlock','unlock !','inlock'],
    wordmin: 1,
    answer: "unlock",
    indice_1: "Tout simplement un escape game en un jeu de cartes et une application gratuite. L'association des cartes rouges et bleues est caractéristique de ce jeu. As d'or-jeu de l'année en 2017. ",
    indice_2: '/miniatures/27.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "244,946 408,920 433,1083 270,1109",
  }, {
    id: 28,
    maxBasePoint: 3,
    wordlist: ['backgammon','backgamon','bakgamon','backgamone','bakgamone'],
    wordmin: 1,
    answer: "backgammon",
    indice_1: "Il s'agit d'un des jeux les plus pratiqués au monde. Les deux joueurs cherchent à faire avancer leurs 15 pions en jetant 2 dés à six faces. Souvent présent dans les malettes multi-jeux. ",
    indice_2: '/miniatures/28.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "410,921 434,1083 598,1057 572,895",
  }, {
    id: 29,
    maxBasePoint: 5,
    wordlist: ['bazar','bizarre','bazarre','bizar','bazzar','bizzare','bazare'],
    wordmin: 2,
    answer: "bazar bizarre",
    indice_1: "Jeu de réflexe où on se retrouve à devoir attraper le plus vite possible de petits objets de couleurs différentes en fonction d'une carte retournée au hasard. Existe en 4 versions : la version de base, la Junior, la 2.0 et la Ultime.",
    indice_2: '/miniatures/29.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "575,895 612,888 612,1053 600,1057",
  }, {
    id: 30,
    maxBasePoint: 5,
    wordlist: ['strike','stryke','strik','stryk'],
    wordmin: 1,
    answer: "strike",
    indice_1: "La magie du jeu c'est aussi d'imaginer des scènes absolument improbables. Ici, ce sont des dés qui vont s'affonter dans une arène : les joueurs essaient de jeter leurs dés dans la mêlée pour survivre, à la croisée entre jeu d'adresse et stop ou encore ",
    indice_2: '/miniatures/30.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "44,1147 104,1139 130,1301 46,1312",
  }, {
    id: 31,
    maxBasePoint: 3,
    wordlist: ['rubik','rubiks','cube',"rubik's",'rubyk','ruby','rubix'],
    wordmin: 2,
    answer: "rubik's cube",
    indice_1: "L'un des objets emblématiques des années 1980 et probablement casse-tête le plus connu.",
    indice_2: '/miniatures/31.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "106,1138 270,1111 294,1274 128,1301",
  }, {
    id: 32,
    maxBasePoint: 3,
    wordlist: ['colon','catane','colons','katane','cattane'],
    wordmin: 1,
    answer: "les colons de catane",
    indice_1: "Souvent cité parmi les jeux qui ont marqué le renouveau du jeu de société. Les joueurs arrivent sur une île pour s'y sinstaller et vont devoir y construire des routes, des bâtiments, avec les ressources qu'ils trouvent sur place et en les troquant avec les autres joueurs. ",
    indice_2: '/miniatures/32.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "272,1112 434,1086 460,1249 296,1273",
  }, {
    id: 33,
    maxBasePoint: 3,
    wordlist: ['tic','tac','boom','boum','tik','tak'],
    wordmin: 2,
    answer: "tic tac boom",
    indice_1: "La bombe circule entre les joueurs au fur et à mesure des bonnes réponses. Répondez rapidement pour vous en débarasser avant l'explosion ! ",
    indice_2: '/miniatures/33.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "436,1086 600,1059 619,1228 462,1248",
  }, {
    id: 34,
    maxBasePoint: 5,
    wordlist: ['skull','roses','rose','squll'],
    wordmin: 2,
    answer: "skull & roses",
    indice_1: "Avant d'être édité, il s'appelait la roulette moldave. As d'or jeu de l'année en 2011, réédité en 2013, toujours avec un matériel étonnant qu'on pourrait croire sorti d'une taverne...",
    indice_2: '/miniatures/34.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "44,1316 131,1305 154,1465 44,1483",
  }, {
    id: 35,
    maxBasePoint: 3,
    wordlist: ['concept','concepte','concepts','koncept'],
    wordmin: 1,
    answer: "concept",
    indice_1: "Pour ce jeu, As d'or-jeu de l'année 2014, le seul moyen pour communiquer est d’utiliser des icônes universelles et de les associer entre elles pour faire deviner votre mot. Marquez des points en étant le premier à trouver le mot des autres joueurs.",
    indice_2: '/miniatures/35.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "133,1303 296,1276 320,1439 158,1465",
  }, {
    id: 36,
    maxBasePoint: 3,
    wordlist: ['pique','plume','pic','plumes'],
    wordmin: 2,
    answer: "pique plume",
    indice_1: "Dans ce jeu qui fête ses 25 ans en 2023, vous tournez autour du poulailler , vous devez rattraper les autres poules/coqs afin d'enlever la plume de leurs croupions. Mais regardez derrière qui arrive ! ",
    indice_2: '/miniatures/36.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "298,1276 459,1250 486,1412 322,1438",
  }, {
    id: 37,
    maxBasePoint: 3,
    wordlist: ['code','names','codename','codenames'],
    wordmin: 1,
    answer: "codenames",
    indice_1: "Dans ce jeu d’association d’idées, les joueurs, répartis en deux équipes, devront tour à tour faire deviner à leurs coéquipiers un ensemble de mots qui leur sont attribués.",
    indice_2: '/miniatures/37.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "463,1252 612,1229 612,1393 487,1411",
  }, {
    id: 38,
    maxBasePoint: 5,
    wordlist: ['twister','twisteur'],
    wordmin: 1,
    answer: "twister",
    indice_1: "Grand besoin de souplesse pour ce jeu au sol qui n'a rien à voir avec la frénésie dansante des 60's. ",
    indice_2: '/miniatures/38.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "44,1485 156,1467 183,1630 45,1651",
  }, {
    id: 39,
    maxBasePoint: 3,
    wordlist: ['small','world','smallworld','smal','word','smalworld','smalword'],
    wordmin: 1,
    answer: "smallworld",
    indice_1: "Ce jeu de Philippe Keyaerts propose aux joueurs de posséder successivement plusieurs civilisations (des nains, des mages, des amazones, des géants, des orcs et même des humains !) puis lors de leur déclin d'en choisr une nouvelle ...",
    indice_2: '/miniatures/39.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "159,1467 322,1442 346,1604 183,1629",
  }, {
    id: 40,
    maxBasePoint: 3,
    wordlist: ['pandemic','pandemie','pandémie','pandemi','pandemy'],
    wordmin: 1,
    answer: "pandémie",
    indice_1: "Dans ce jeu coopéraif de Matt Leacock, les joueurs incarnent des scientifiques ayant chacun une compétence spécifique menant une course contre la montre face au développement de foyers infectieux à travers le monde. ",
    indice_2: '/miniatures/40.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "324,1442 485,1413 513,1579 348,1603",
  }, {
    id: 41,
    maxBasePoint: 3,
    wordlist: ['time','stories','times','storie','story'],
    wordmin: 2,
    answer: "time stories",
    indice_1: "Dans ce jeu de Manuel Rozoy , les joueurs incarnent des agents temporels, expédiés à travers le temps ou dans des univers parallèles. Les agents sont envoyés dans des corps-réceptacles dotés de caractéristiques spécifiques, qui leur permettent d'affronter toutes sortes de situations.",
    indice_2: '/miniatures/41.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "488,1415 612,1395 611,1562 512,1578",
  }, {
    id: 42,
    maxBasePoint: 3,
    wordlist: ['kingdomino','kingdominos'],
    wordmin: 1,
    answer: "kingdomino",
    indice_1: "Des tuiles plates séparées en deux parties, pas de chiffres, mais des paysages, avec parfois des couronnes… ça ne vous dit rien ? Un jeu de Bruno Cathala, illustré par Cyril Bouquet et édité en 2016.",
    indice_2: '/miniatures/42.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "44,1655 182,1633 209,1796 45,1819",
  }, {
    id: 43,
    maxBasePoint: 3,
    wordlist: ['jungle','speed','spid','speeds','jungl'],
    wordmin: 2,
    answer: "jungle speed",
    indice_1: "Dans ce jeu haut en couleur de Thomas Vuarchex et Pierric Yakovenko, retournez une de vos cartes et attrapez le totem si vous avez le même symbole qu'un adversaire ! Simple mais diaboliquement efficace !",
    indice_2: '/miniatures/43.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "186,1632 348,1606 373,1769 210,1794",
  }, {
    id: 44,
    maxBasePoint: 3,
    wordlist: ['woopy','woopi','woopie','woopys','woopis','woopies','woupie','woupye','woupi','woupy','woupis','woupys'],
    wordmin: 1,
    answer: "woopy",
    indice_1: "La mascotte du plus grand des festivals des jeux !!!",
    indice_2: '/miniatures/44.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "350,1605 511,1580 539,1743 374,1768",
  }, {
    id: 45,
    maxBasePoint: 5,
    wordlist: ['mr','jack','mister','jak','m.','jacque','jacques','jacks'],
    wordmin: 2,
    answer: "mr jack",
    indice_1: "Entre ombre et lumière, ce duel créé par Bruno Cathala et Ludovic Maublanc est le premier jeu illustré par Pierô. Il a connu une première édition confidentielle sous le nom de 'une ombre sur Whitechapel' en 2005. ",
    indice_2: '/miniatures/45.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "515,1579 612,1565 612,1731 538,1742",
  }, {
    id: 46,
    maxBasePoint: 3,
    wordlist: ['colt','express','expres','expresse','kolt'],
    wordmin: 2,
    answer: "colt express",
    indice_1: "Avec sa maquette de train en 3D posée sur la table, ce jeu propose aux joueurs de programmer leurs actions de bandits ferroviaires au far west. Récompensé par l'As d'or -jeu de l'année en 2015. ",
    indice_2: '/miniatures/46.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "47,1822 209,1797 234,1960 70,1985",
  }, {
    id: 47,
    maxBasePoint: 3,
    wordlist: ['blokus','blokkus','bloquus','blocus'],
    wordmin: 1,
    answer: "blokus",
    indice_1: "Dans ce jeu coloré aux airs de Tétris posé à plat, ne touchez pas les bords !",
    indice_2: '/miniatures/47.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "212,1796 374,1771 400,1934 236,1960",
  }, {
    id: 48,
    maxBasePoint: 3,
    wordlist: ['love','letter','letters'],
    wordmin: 2,
    answer: "love letter",
    indice_1: "Un jeu de canaille tenant dans un petit sac : 16 cartes en tout et pour tout, ce jeu minimaliste d'origine japonaise (Seiji Kanai) a été traduit pour la première fois en français par Filosofia en 2013",
    indice_2: '/miniatures/48.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "373,1772 538,1744 565,1908 401,1934",
  }, {
    id: 49,
    maxBasePoint: 5,
    wordlist: ['danse','oeufs','oeuf','salsa'],
    wordmin: 2,
    answer: "la danse des oeufs",
    indice_1: "Un jeu de Roberto Fraga où l’on doit, entre autres, coincer des oeufs en plastique dans son cou, entre ses genoux sans les faire tomber et plus vite que les autres joueurs.",
    indice_2: '/miniatures/49.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "541,1745 613,1732 612,1900 564,1910",
  }, {
    id: 50,
    maxBasePoint: 5,
    wordlist: ['trivial','pursuit','poursuit','triviale','poursuite'],
    wordmin: 2,
    answer: "trivial pursuit",
    indice_1: "Probablement le plus connu des jeux de quizz, à l'origine il portait également le nom de Remue Méninges. Mais vous reprendrez bien un peu de camembert ? ",
    indice_2: '/miniatures/50.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "45,1991 70,1989 98,2152 44,2158",
  }, {
    id: 51,
    maxBasePoint: 3,
    wordlist: ['loup','garous','loups','thiercelieux','garoux','thiercelieu'],
    wordmin: 2,
    answer: "les loups-garous de thiercelieux",
    indice_1: "Le Grand classique des jeux d'ambiance : Le village s'endort, fermez les yeux..... Attention, certains peuvent tricher tout en suivant les règles du jeu !",
    indice_2: '/miniatures/51.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "72,1988 234,1963 260,2125 98,2150",
  }, {
    id: 52,
    maxBasePoint: 3,
    wordlist: ['hanabi','hanabie','hanaby','hanabye','hannabi','hannaby'],
    wordmin: 1,
    answer: "hanabi",
    indice_1: "Ce premier jeu d’Antoine Bauza a gagné le jeu de l’année en Allemagne en 2013, avec son thème sur les feux d'artifices, il reste une référence en matière de jeu coopératif. ",
    indice_2: '/miniatures/52.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "237,1961 400,1937 425,2099 262,2125",
  }, {
    id: 53,
    maxBasePoint: 3,
    wordlist: ['jenga','djenga'],
    wordmin: 1,
    answer: "jenga",
    indice_1: "Empiler et enlever des bâtons , mais ne faites pas tout tomber avec vos gros doigts...",
    indice_2: '/miniatures/53.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 3,
    done: 0,
    polypoints: "402,1935 564,1910 590,2073 426,2097",
  }, {
    id: 54,
    maxBasePoint: 5,
    wordlist: ['zombicide','zombycide','zombicyde','zombicid','zombycid'],
    wordmin: 1,
    answer: "zombicide",
    indice_1: "Les jeux financés par des campagnes participatives en France doivent beaucoup à ce jeu de survie contre des hordes de morts-vivants, pourvu de nombreuses figurines dans une grosse boîte carrée. ",
    indice_2: '/miniatures/54.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "566,1909 612,1905 612,2069 591,2072",
  }, {
    id: 55,
    maxBasePoint: 5,
    wordlist: ['six','qui','prend','6','prends'],
    wordmin: 2,
    answer: "6 qui prend",
    indice_1: "Jeu de cartes uniquement, aujourd'hui encore vendu dans une boite en métal. Si vous êtes le dernier, vous prendrez le taureau par les cornes ! La version junior fait son apparition en boutique en 2023.",
    indice_2: '/miniatures/55.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "44,2161 96,2155 122,2317 45,2327",
  }, {
    id: 56,
    maxBasePoint: 5,
    wordlist: ['risk','rysk','risque'],
    wordmin: 1,
    answer: "risk",
    indice_1: "A la conquête du monde ! Un classique indémodable dans lequel vous ne pourrez éviter les conflits frontaliers Ce jeu a été adapté avec de nombreuses licences. ",
    indice_2: '/miniatures/56.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "100,2153 260,2126 272,2197 149,2197 148,2312 124,2317",
  }, {
    id: 57,
    maxBasePoint: 5,
    wordlist: ['patchwork','patchworks','patch','work'],
    wordmin: 1,
    answer: "patchwork",
    indice_1: "Dans ce jeu créé par Uwe Rosenberg, jouable uniquement à deux, il n’y a pas besoin de savoir coudre pour remporter la partie, mais avoir joué à Tetris est un plus. La monnaie du jeu ? Des boutons bien sûr ! ",
    indice_2: '/miniatures/57.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "261,2127 425,2100 442,2197 275,2197",
  }, {
    id: 58,
    maxBasePoint: 5,
    wordlist: ['quarto','quarto !','quatro'],
    wordmin: 1,
    answer: "quarto",
    indice_1: "Le Premier jeu devenu emblématique de l'éditeur Gigamic, jeu abstrait par excellence, en bois, souvent une version géante dans les festivals de jeu.",
    indice_2: '/miniatures/58.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "428,2100 590,2075 610,2192 613,2244 506,2255 505,2196 444,2196",
  }, {
    id: 59,
    maxBasePoint: 5,
    wordlist: ['7','wonders','seven','wonder'],
    wordmin: 2,
    answer: "7 wonders",
    indice_1: "As d'or -jeu de l'année en 2011, Antoine Bauza a eu tellement de succès avec ce jeu de draft et de civilisation qu'il en a imaginé une version deux joueurs avec Bruno Cathala. ",
    indice_2: '/miniatures/59.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "45,2330 122,2318 149,2483 44,2496",
  }, {
    id: 60,
    maxBasePoint: 5,
    wordlist: ['qwirkle','qwyrkle','qwirqule','qwirquel','kwirkle'],
    wordmin: 1,
    answer: "qwirkle",
    indice_1: "Des dominos arc-en-ciel ! Aligner les formes ou les couleurs pour gagner des points. Particularité : seulement 2 voyelles pour 5 consonnes dans son nom !",
    indice_2: '/miniatures/60.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "125,2318 148,2314 150,2477",
  }, {
    id: 61,
    maxBasePoint: 5,
    wordlist: ['petits','chevaux','chevau','petit'],
    wordmin: 2,
    answer: "les petits chevaux",
    indice_1: "Un six pour sortir et commencer à faire le tour du plateau ! Sans six, la partie est longue. Très longue.",
    indice_2: '/miniatures/61.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "505,2256 611,2241 612,2408 505,2424",
  }, {
    id: 62,
    maxBasePoint: 5,
    wordlist: ['poker','pocker'],
    wordmin: 1,
    answer: "poker",
    indice_1: "Le Texas hod'em en est probablement la variante la plus jouée. Jeu emblématique des saloons du far west, si populaire qu'il compte aujourd'hui des joueurs professionnels dans le monde entier.",
    indice_2: '/miniatures/62.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "45,2499 148,2480 149,2650 45,2665",
  }, {
    id: 63,
    maxBasePoint: 5,
    wordlist: ['citadelle','citadel','citadele'],
    wordmin: 1,
    answer: "citadelle",
    indice_1: "Bruon Faidutti a clairement marqué l'Histoire ludique en tout début de millénaire avec ce jeu à identités cachées et ces dénouements parfois cruels entre les constructeurs de bâtiments. ",
    indice_2: '/miniatures/63.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "505,2426 611,2409 611,2577 506,2592",
  }, {
    id: 64,
    maxBasePoint: 5,
    wordlist: ['verger'],
    wordmin: 1,
    answer: "le verger",
    indice_1: "Un corbeau et des fruits pour ce jeu coopératif édité par l'illustre marque aux boîtes jaunes : Haba. ",
    indice_2: '/miniatures/64.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "44,2667 148,2650 148,2703 183,2700 200,2811 44,2834",
  }, {
    id: 65,
    maxBasePoint: 5,
    wordlist: ['scrabble','scrable','scrabeul'],
    wordmin: 1,
    answer: "scrabble",
    indice_1: "Des mots croisés sur un plateau de jeu ? Papi et mamie sont plus forts que vous, c'est sûr ! ",
    indice_2: '/miniatures/65.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "186,2702 352,2702 365,2784 202,2810",
  }, {
    id: 66,
    maxBasePoint: 5,
    wordlist: ['tantrix','tantris'],
    wordmin: 1,
    answer: "tantrix",
    indice_1: "Jeu de pose de tuiles hexagonales tout droit venu de Nouvelle-Zélande, sur lesquelles les chemins verts, bleus, jaunes et rouges cherchent à se rejoindre. ",
    indice_2: '/miniatures/66.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "355,2702 506,2702 502,2590 531,2759 366,2783",
  }, {
    id: 67,
    maxBasePoint: 5,
    wordlist: ['jamaïca','jamaica','jamaiqua','jamaïqua'],
    wordmin: 1,
    answer: "jamaïca",
    indice_1: "A l'abordage ! Piraterie ! Comment une simple course de navires assoiffés d'or autour d'une île bien connue peut tourner en bataille navale sans pitié ? Un petit verre de rhum avant de repartir ? Sébastien Pauchon et Malcolm Braff à la barre. ",
    indice_2: '/miniatures/67.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "507,2595 611,2579 611,2746 532,2758",
  }, {
    id: 68,
    maxBasePoint: 5,
    wordlist: ['petit','meurtres','fait','divers','petits','meurtre','faits','diver'],
    wordmin: 4,
    answer: "petits meurtres et fait divers",
    indice_1: "Un inspecteur, des suspects, un coupable et peut-être même un greffier autour de la table, les dépositions s'enchaînent à propos d'affaires loufoques et inattendues. ",
    indice_2: '/miniatures/68.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "44,2837 200,2810 206,2851 46,2851",
  }, {
    id: 69,
    maxBasePoint: 5,
    wordlist: ['perudo','peroudo','perrudo'],
    wordmin: 1,
    answer: "perudo",
    indice_1: "Chacun son verre ! Mais c’est quand il est dans le mauvais sens que tout va bien. Ce jeu exotique et bruyant vous propose de secouer vos dés. Attention, les toucans brouillent les pistes.",
    indice_2: '/miniatures/69.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "203,2811 365,2787 375,2850 208,2851",
  }, {
    id: 70,
    maxBasePoint: 5,
    wordlist: ['pitch','car','pich','cars'],
    wordmin: 2,
    answer: "pitch car",
    indice_1: "Apparu initialement sous le nom Carabande. Un jeu de billes, mais sans les billes : plutôt que d'en faire rouler, propulsez votre formule 1 sur des circuits modulables en bois, en évitant les sorties de route !",
    indice_2: '/miniatures/70.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "368,2785 531,2760 544,2851 378,2851",
  }, {
    id: 71,
    maxBasePoint: 5,
    wordlist: ['azul','azule','azoul','asul'],
    wordmin: 1,
    answer: "azul",
    indice_1: "Alignez les carreaux de faience dans la bonne séquence dans ce magnifique jeu de mosaïques . As d'or-jeu de l'année en 2018, il est aujourd'hui disponible dans de nombreuses déclinaisons. ",
    indice_2: '/miniatures/71.jpg',
    indice_1_show: 0,
    indice_2_show: 0,
    maxPoint: 5,
    done: 0,
    polypoints: "532,2760 612,2748 613,2851 547,2851",
  }
];

let theme = createTheme({
  shape: {
    pillRadius: 50
  }
});

const GameBoard = () => {

  const [showdlgmessage, setDialogMessage] = useState(false);
  const [showdlgmessagewin, setShowDialogMessageWin] = useState(false);
  const [showdlgquestion, setShowDialogBox] = useState(false);
  const [currentTitle, setCurrentTitle] = useState('')
  const [currentMessage, setCurrentMessage] = useState('')
  const [showmessage_badanswer, setshowmessage_badanswer] = useState(false);
  const [userAnswer, setUserAnswer] = useState('');

  const [currentUsername, setCurrentUsername] = useState('')
  const [currentEmail, setCurrentEmail] = useState('')
  const [currentScore, setcurrentScore] = useState(0)

  const [toptenimg, setTopTen] = useState('');

  const [images, setImages] = useState(initial_list)
  const [currentTileindex, setcurrentTileindex] = useState(0);

  const BtnClick_indice = () => {
    if (images[currentTileindex].maxBasePoint === 5) {
      if (images[currentTileindex].indice_2_show == 0) {
        //Révele l indice image
        images[currentTileindex].indice_2_show = 1;
        images[currentTileindex].maxPoint = 3;
      } else {
        images[currentTileindex].indice_1_show = 1;
        images[currentTileindex].maxPoint = 1;
      }
    } else {
      images[currentTileindex].indice_1_show = 1;
      images[currentTileindex].indice_2_show = 0; //juste au cas ou
      images[currentTileindex].maxPoint = 1;
    }
    refreshWOG();
  }

  const closeDialogMessage = () => {
    setDialogMessage(false);
    setShowDialogMessageWin(false);
    setCurrentMessage('');
    setCurrentTitle('');
  }

  const handleClose = (event, reason) => {
    if(localStorage.getItem('userid') !== null){
      if (reason && reason == "backdropClick")
        return;
      }
  }

  const handleCloseGuess = () => {
    setShowDialogBox(false);
    setUserAnswer('');
    setshowmessage_badanswer(false)
  };
  const handleConnect = (e) => {
    if (currentUsername === "") {
      setCurrentTitle("Erreur : ");
      setCurrentMessage("Impossible de vous inscrire sans un pseudo");
      setDialogMessage(true);
      closeMessageTimeOut(5000);
      return
    }
    if (currentEmail === "") {
      setCurrentTitle("Erreur : ");
      setCurrentMessage("Impossible de vous inscrire sans une adresse mail");
      setDialogMessage(true);
      closeMessageTimeOut(5000);
      return
    }
    if(!isValidEmail(currentEmail)){
      setCurrentTitle("Erreur : ");
      setCurrentMessage("Adresse mail non valide");
      setDialogMessage(true);
      closeMessageTimeOut(5000);
      return
    }
    
    e.preventDefault();
    fetch('https://wallofgame-apiimperiale.parthenay.fr/wogconnect', {
      method: 'POST',
      body: JSON.stringify(
        { username: currentUsername, email: currentEmail }
      ),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    }).then(response => response.json()).then(data => {
      if (data.err === 5) {
        localStorage.removeItem('username');
        localStorage.removeItem('email');
        localStorage.removeItem('userid');
        //localStorage.removeItem('userscore');
        setCurrentTitle("L'inscription a échoué : ");
        setCurrentMessage(data.message);
        setDialogMessage(true);
        closeMessageTimeOut(5000);
      } else {
        localStorage.setItem('username', data.username);
        localStorage.setItem('userid', data.userid);
        localStorage.setItem('email', data.email);
        //localStorage.setItem('userscore',data.userscore);
        setcurrentScore(data.userscore)
        
        AutoLogin(false);
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  // const handleSignup = (e) => {
  //   if (currentUsername === "") {
  //     setCurrentTitle("Erreur : ");
  //     setCurrentMessage("Impossible de vous inscrire sans un pseudo");
  //     setDialogMessage(true);
  //     closeMessageTimeOut(5000);
  //     return
  //   }
  //   if (currentEmail === "") {
  //     setCurrentTitle("Erreur : ");
  //     setCurrentMessage("Impossible de vous inscrire sans une adresse mail");
  //     setDialogMessage(true);
  //     closeMessageTimeOut(5000);
  //     return
  //   }
  //   if(!isValidEmail(currentEmail)){
  //     setCurrentTitle("Erreur : ");
  //     setCurrentMessage("Adresse mail non valide");
  //     setDialogMessage(true);
  //     closeMessageTimeOut(5000);
  //     return
  //   }
    
  //   e.preventDefault();
  //   fetch('https://wallofgame-apiimperiale.parthenay.fr/signup', {
  //     method: 'POST',
  //     body: JSON.stringify(
  //       { username: currentUsername, email: currentEmail }
  //     ),
  //     headers: {
  //       'Content-type': 'application/json; charset=UTF-8'
  //     }
  //   }).then(response => response.json()).then(data => {
  //     if (data.err === 5) {
  //       localStorage.removeItem('username');
  //       localStorage.removeItem('email');
  //       localStorage.removeItem('userid');
  //       //localStorage.removeItem('userscore');
  //       setCurrentTitle("L'inscription a échoué : ");
  //       setCurrentMessage(data.message);
  //       setDialogMessage(true);
  //       closeMessageTimeOut(5000);
  //     } else {
  //       localStorage.setItem('username', data.username);
  //       localStorage.setItem('userid', data.userid);
  //       localStorage.setItem('email', data.email);
  //       //localStorage.setItem('userscore',data.userscore);
  //       setcurrentScore(data.userscore)
        
  //       AutoLogin(false);
  //     }
  //   }).catch((err) => {
  //     console.log(err);
  //   });
  // };

  const getTopten = async () => {
    console.log("getTopten")
    try {
      const response = await fetch('https://wallofgame-apiimperiale.parthenay.fr/topten');
      const data = await response.json();

      setTopTen("data:image/jpeg;base64," + data.toptenimg);

    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };

  // const handleLogin = (e) => {
  //   e.preventDefault();
  //   if (currentUsername === "") {
  //     setCurrentTitle("Erreur : ");
  //     setCurrentMessage("Impossible de vous connecter sans un pseudo");
  //     setDialogMessage(true);
  //     closeMessageTimeOut(5000);
  //     return;
  //   }
  //   if (currentEmail === "") {
  //     setCurrentTitle("Erreur : ");
  //     setCurrentMessage("Impossible de vous connecter sans une adresse mail");
  //     setDialogMessage(true);
  //     closeMessageTimeOut(5000);
  //     return;
  //   }

  //   localStorage.setItem('username', currentUsername);
  //   localStorage.setItem('email', currentEmail);
  //   AutoLogin();
  // };

  const generateUsername = () => {
    const timestamp = new Date().toISOString();
    const milliseconds = timestamp.slice(-9);
    const username = `player${milliseconds}`;
    const clean = username.replace(':', '');
    const cleaner = clean.replace('.', '');
    return cleaner;
  };

  const scriptedLogin = (e) => {
    e.preventDefault();
    localStorage.setItem('username', generateUsername());
    localStorage.setItem('email', 'wog-citedesjeux@parthenay.fr');
    
    fetch('https://wallofgame-apiimperiale.parthenay.fr/wogconnect', {
      method: 'POST',
      body: JSON.stringify(
        { username: localStorage.getItem('username') , email: localStorage.getItem('email') }
      ),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    }).then(response => response.json()).then(data => {
      if (data.err === 5) {
        setCurrentTitle("L'inscription a échoué : ");
        setCurrentMessage(data.message);
        localStorage.removeItem('username');
        localStorage.removeItem('email');
        localStorage.removeItem('userid');
        //localStorage.removeItem('userscore');
        setDialogMessage(true);
        closeMessageTimeOut(5000);
      } else {
        AutoLogin();
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const handleClick = (event, _id) => { //action derriere le clique sur un polygone
    event.preventDefault();
    var currentTileindex = images.indexOf(images.find((images) => images.id === _id));
    setcurrentTileindex(currentTileindex);

    if (images[currentTileindex].done == 1) {
      //Affiche le dialogue de la solution
      setShowDialogBox(false);
      setCurrentTitle('');
      setShowDialogMessageWin(true);
    } else {
      setShowDialogBox(true);
    }
  }

  const handleUserAnswerChange = (event) => {
    setUserAnswer(event.target.value);
    setshowmessage_badanswer(false);
  }

  const handleKeyPress = (event) => {
    // Vérifiez si la touche pressée est "Entrée" (keyCode 13)
    if (event.key === 'Enter') {
      handleUserAnswerSubmit();
    }
  };

  const handleUsernameChange = (event) => {
    setCurrentUsername(event.target.value);
  }

  const handleEmailChange = (event) => {
    setCurrentEmail(event.target.value);
  }
  const handleUserAnswerSubmit = () => {
    let tmpans = removeSpecialCharacters(userAnswer.toLowerCase()).split(" ");//.replace(","," ").replace("."," ").replace("-"," ").replace("_"," ").replace("  "," ")
    var cpt = 0;
    tmpans.forEach(function (value, ind) {
      images[currentTileindex].wordlist.forEach(function (ansval, ansind) {
        if (value === ansval) {cpt += 1;}
      });
    });
    if (cpt >= images[currentTileindex].wordmin) {
      //localStorage.setItem('userscore',parseInt(localStorage.getItem('userscore') )+ parseInt(images[currentTileindex].maxPoint));
      let newsc = currentScore + images[currentTileindex].maxPoint
      //currentScore = newsc;
      setcurrentScore(newsc);
      images[currentTileindex].done = 1;
      refreshWOG();

      setUserAnswer('');
      setShowDialogBox(false);
      setCurrentTitle("Bravo !!! ")
      setCurrentMessage("Bonne réponse, vous avez gagné " + images[currentTileindex].maxPoint + " points")
      
      setShowDialogMessageWin(true);
      closeMessageTimeOut(5000);
    }
    else {
      setshowmessage_badanswer(true);
    }
  }
  const handleMouseOver = (id) => {
    const imageZoomContainers = document.getElementsByClassName('image-zoom-container');
    for (let i = 0; i < imageZoomContainers.length; i++) { imageZoomContainers[i].style.zIndex = ''}
    document.getElementById(id).style.zIndex = 1;
  };

  useEffect(() => {
    getTopten();
    let interval = setInterval(() => { getTopten()}, 10000);

    if (localStorage.getItem('userid')) { AutoLogin(false);}
    
    // Nettoyer l'écouteur d'événement lors du démontage du composant
    return () => {clearInterval(interval);};
  }, []);
  
  useEffect(() => {
    if (currentTileindex != 0) {
      
      //console.log("updateinfo");
      fetch('https://wallofgame-apiimperiale.parthenay.fr/updateinfo', {
        method: 'POST',
        body: JSON.stringify(
          { userid: localStorage.getItem('userid'), infos: images[currentTileindex], userScore : currentScore}
        ),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      });
      getTopten();
    }

  }, [images]);
  
  function closeMessageTimeOut(timer) {
    setTimeout(() => closeDialogMessage(), timer)
  };
  
  function refreshWOG(){
    setImages((images) => {
      return images.map((item) => {
        if (item.id === 0) {
          return {
            ...item,
            done: 1,
          };
        }
        return item;
      });
    });
      
  }

  function loadUserBoard(ub_h1, ub_h2, ub_win) {
    ub_h1.forEach(function (value, ind) {
      if (ind > 2) {//0=id et 1 = userid et 3 =tile0 !
        images[ind - 2].indice_1_show = ub_h1[ind];
        images[ind - 2].indice_2_show = ub_h2[ind];
        images[ind - 2].done = ub_win[ind];
        if (ub_h2[ind] == 1) { images[ind - 2].maxPoint = 3; }
        if (ub_h1[ind] == 1) { images[ind - 2].maxPoint = 1; }
      }
    });
    refreshWOG();
  };


  function isValidEmail(email) {
    // Expression régulière pour valider le format de l'adresse e-mail
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Teste si l'e-mail correspond au format attendu
    return emailRegex.test(email);
  }
  
  function removeSpecialCharacters(inputString) {
    // Mappings de caractères spéciaux à remplacer
    const specialCharactersMap = {
      'à|á|å|â': 'a',
      'è|é|ê|ẽ|ë': 'e',
      'ì|í|î': 'i',
      'ò|ó|ô|ø': 'o',
      'ù|ú|ů|û': 'u',
      'ç': 'c',
      'ñ': 'n',
      'ß': 'ss',
      'ÿ': 'y',
      'æ': 'ae',
      'œ': 'oe',
    };
    let resultString = inputString;
    // Remplacer les caractères spéciaux en utilisant les mappings

    for (const [pattern, replacement] of Object.entries(specialCharactersMap)) {
      const regex = new RegExp(pattern, 'g');
      resultString = resultString.replace(regex, replacement);
    }

    // Supprimer les caractères spéciaux restants
    resultString = resultString.replace(/[^\w\s]/gi, '');

    return resultString;
  }

  function AutoLogin(showconnect = true) {
    fetch('https://wallofgame-apiimperiale.parthenay.fr/wogconnect', {
      method: 'POST',
      body: JSON.stringify(
        { username: localStorage.getItem('username'), email: localStorage.getItem('email') }
      ),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    })
      .then(response => response.json()).then(data => {
        if (data.err === 5) {
          setCurrentTitle("Erreur");
          setCurrentMessage("Impossible de retrouver " + localStorage.getItem('username') + " avec cet email");
          localStorage.removeItem('username');
          localStorage.removeItem('email');
          localStorage.removeItem('userid');
          //localStorage.removeItem('userscore');
          setDialogMessage(true);
          closeMessageTimeOut(5000);
          return;
        }

        localStorage.setItem('username', data.username);
        localStorage.setItem('email', data.email);
        localStorage.setItem('userid', data.userid);
        //localStorage.setItem('userscore', data.user_info[1]);
        setcurrentScore(data.user_info[1]);

        var ub_h1_array = (data.hint1).toString().split(',');
        var ub_h2_array = (data.hint2).toString().split(',');
        var ub_win_array = (data.win).toString().split(',');
        loadUserBoard(ub_h1_array, ub_h2_array, ub_win_array);

        handleClose();
        if (showconnect) {
          setCurrentTitle("Connexion au compte de " + data.username + " réussie");
          setCurrentMessage("Votre score est de " + data.score);
          setDialogMessage(true);
          closeMessageTimeOut(5000);
        }
      }).catch((err) => {
        console.log(err);
      });
  }  
  return (<div>

    {localStorage.getItem('userid') ? null :
      <Dialog
        onClose={localStorage.getItem('userid')}
        open={localStorage.getItem('userid') === null}
        fullWidth>
        <div className='dialogbox'>
        <DialogTitle className='dialogbox_title'>Wall of Game</DialogTitle>
          <div>
            <p style={
              {
                fontSize: 18,
                textAlign: 'justify'
              }
            }>
              <b>
                <u>
                  Règles du jeu :
                </u>
              </b>
              <br />
              <br />
            </p>
            <p className='dialogueregledujeu'>
              Retrouvez le nom des 70 jeux de société qui se cachent parmi les dessins.
              <br />
              Chaque image partielle vaut 5 points, une image complète vaut 3 points.
              <br />
              Vous ne trouvez pas ? Utilisez les indices. Attention : l'utilisation des indices réduira le nombre de points gagnés.
              <br />
              Le plus grand score possible est de 286 points.
              <br />
              Bonne chance et amusez-vous bien !
            </p>
          </div>
          <br />
          <div className='dialogueconnect'>
            <div className="dialoglogin">Pseudo:
              <input className='dialogbox_input' maxLength={20}
                type="text"
                value={currentUsername}
                onChange={handleUsernameChange}
                placeholder=""
              />&nbsp;&nbsp;&nbsp;
            </div>
            <div className="dialoglogin">Email :
              <input className='dialogbox_input'
                maxLength={50}
                type="text"
                value={currentEmail}
                onChange={handleEmailChange}
                placeholder=""
              />
            </div>
          </div>
          <br />
          <div className='dialoguebuttons'>
          <Button variant="contained" 
              onClick={
                (e) => handleConnect(e)
              }>
              Se connecter
            </Button>
            {/* <Button variant="contained" 
              onClick={
                (e) => handleSignup(e)
              }>
              Créer un compte
            </Button>
            <div className='dialoguebtnret1'>&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <div className='dialoguebtnret2'><br /></div>

            <Button variant="contained" 
              onClick={
                (e) => handleLogin(e)
              }>
              Reprendre une partie
            </Button> */}
          </div>
          <br />
          <Button variant="outlined"
            onClick={
              (e) => scriptedLogin(e)
            }>
            Jouer en anonyme
          </Button>
          <br /> <br />

          <div className='dialoguecnil' >
            <p >
              <i>
                Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par la Ville de Parthenay pour le classement des joueurs des applications web de la cité des jeux.
              </i>
            </p>
            <br />
          </div>
          </div>
          </Dialog>}
      <Dialog onClose={closeDialogMessage}
        open={showdlgmessagewin} fullWidth>
        <DialogTitle>{currentTitle}Il fallait bien trouver {images[currentTileindex].answer} !</DialogTitle>
        <IconButton aria-label="close"
        onClick={closeDialogMessage}
        sx={
          {
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }
        }>
        <CloseIcon />
        </IconButton>
        <div className='dialogbox'>{currentMessage}<br/>
        {/* style={{ zIndex: isZoomed ? 999 : '1' }} */}
        <div className="image-zoom-container" id='imgRefMini'>
          <img className='img-can-be-zoomed'
            src={'/tile/' + images[currentTileindex].id + '.jpg'}
            alt="Zoomable Image" 
            onMouseOver={() => handleMouseOver('imgRefMini')}
          />
        </div>

        {/* onMouseOut={handleMouseOut} */}
        {/* {isZoomed && (
          <div className="zoomed-view">
            <img src={'/tile/' + images[currentTileindex].id + '.jpg'} alt="Zoomed Image"
            style={{ zIndex: isZoomed ? 1 : 'auto' }} />
          </div>
        )} */}
        &nbsp;&nbsp;
        <div className="image-zoom-container" id='imgRefJeux'>
          <img className='img-can-be-zoomed'
            src={'/jeux/' + images[currentTileindex].id + '.jpg'}
            alt="Zoomable Image" 
            onMouseOver={() => handleMouseOver('imgRefJeux')}   
          />
        </div>
        {/* {isZoomed && (
          <div className="zoomed-view">
            <img src={'/jeux/' + images[currentTileindex].id + '.jpg'} alt="Zoomed Image" />
          </div>
         
        )} */}
        <br/>
        <label style={{ fontSize: 20 }}>{images[currentTileindex].indice_1}</label>
        <br/><br/>
        
        </div>
      </Dialog>

    <Dialog onClose={closeDialogMessage}
      open={showdlgmessage} fullWidth>
      <DialogTitle>{currentTitle}</DialogTitle>
      <div className='dialogbox'>
        <p style={
          { fontSize: 16 }
        }>
          {currentMessage} </p>
      </div>
    </Dialog>
     
    < Navbar />
    { localStorage.getItem('username') ?
    <div className='scoreContainer'>
      {/* <h1>Mon score : {localStorage.getItem('userscore') }</h1></div> */}
      <h1>Mon score : {currentScore}</h1></div>
      :null}
    <Dialog onClose={handleCloseGuess}
      open={showdlgquestion}
      fullWidth >
      <DialogTitle></DialogTitle>
      <IconButton aria-label="close"
        onClick={handleCloseGuess}
        sx={
          {
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }
        }>
        <CloseIcon />
      </IconButton>
      <div className='dialogbox_question dialogbox'>
      <div className="image-zoom-container" id='imgRefMini'>
        <img className='img-can-be-zoomed'
          src={images[currentTileindex].indice_2}
          onMouseOver={() => handleMouseOver('imgRefMini')}
        />
        </div>
        <br></br>
        <div className='container-input-guess'>
          <label></label>
          <input type="text"
            value={userAnswer}
            onChange={handleUserAnswerChange}
            onKeyPress={handleKeyPress}
            placeholder="Devinez quel est ce jeu" />
        </div>
        <div className='container-guessText'>
          <br />

          {showmessage_badanswer ?
            <p >mauvaise réponse, essayez encore !</p>
            : <br />
          }

        </div>
        <div className='container-guessText'><Button variant="contained"
            onClick={() => handleUserAnswerSubmit()}>
          Jouer pour gagner {images[currentTileindex].maxPoint}&nbsp;points
        </Button>

          <br /><br />
          {
            images[currentTileindex].indice_2_show == 1 ? 
              <div className="image-zoom-container" id='imgRefTile'>
              <img className='img-can-be-zoomed'
                src={'/tile/' + images[currentTileindex].id + '.jpg'}
                onMouseOver={() => handleMouseOver('imgRefTile')}
                />

            </div> : null
          }
          {
            images[currentTileindex].indice_1_show == 1 ? <div>
              <label style={{ fontSize: 20 }}>{images[currentTileindex].indice_1}</label>
            </div> : null
          }
          <br />

          {images[currentTileindex].indice_1_show == 1 ?
            null :
            <Button variant="outlined" onClick={BtnClick_indice}>
              Révéler un indice
            </Button>
          }
        </div>
      </div>
    </Dialog>
    <div className='gameboard-container'>
      < svg className='svg-gameboard' version="1.1" xmlns="http://www.w3.org/2000/svg" width="656" height="3000" viewBox="0 0 656 3000" >
        {
          toptenimg && (
            <image xlinkHref={toptenimg}
              x="155"
              y="2200"
              width="345"
              height="500"
            />
          )
        }

        <a href="https://www.parthenay.fr/cite-des-jeux" target='_blank'>
          <polygon points="200,2200 200,2400 450,2400 450,2280" opacity="0"></polygon>
        </a>
        
        {images.map((image, index) => (
          index === 7 ? (
          <a key={index} href="#">
            <polygon
              onClick={() => window.open('https://www.parthenay.fr/cite-des-jeux', '_blank')}
              points={image.polypoints}
              style={{ opacity: 0 }}
            />
          </a>)
          : (
          <a key={index} >
            <polygon
              id={'tile' + index}
              onClick={(event) => handleClick(event, index)}
              points={image.polypoints}
              style={image.done == 1 ? null : { opacity: 0 }}
              className={image.done == 1 ? 'polyStyleWin': null}
            />
          </a>)
        ))}

      </svg>
    </div>
    <div className='container-credits'>
      <p style={{ color: 'white' }}>
        <font style={{ fontSize: 24 }} ><b>Wall of Game</b></font>
        <br></br>
        Œuvre réalisée par Tom Vuarchex, en partenariat avec Cocktail Games.
        <br></br>
        Idée originale de Tom Vuarchex et Gaël Fréret.
        <br></br>
        Remerciements :
        <br></br>
        Développement du jeu : service informatique Ville de Parthenay
        <br></br>
        Rédaction des indices : Pierrick Legalou, Shady Ramsis, Gaël Fréret
      </p>
    </div>
    <Footer />
  </div>
  );
}

export default GameBoard;