import React from 'react'
import axios from 'axios'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import Find from '../find/Find'
import Login from '../login/Login'
import { useState, useEffect } from 'react'

import { Button } from "../button/Button";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


import { height } from "@mui/system";

import Fresque from '../../images//bg_fresque.png'
import Tile from '../../images//tile/1.jpg'
import Wuppie from '../../images//Wuppie.PNG'

import img_hint_1 from "../../images//tile/1.jpg"
import img_hint_2 from "../../images//tile/2.jpg"

import '../../App.css';

import { Box, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

let theme = createTheme({
  shape: {
    pillRadius: 50
  }
});

const Maintenance = () => {
    const [openDialog, handleDisplay] = React.useState(true);
    const [dialogMessage, setDialogMessage] = React.useState(false);
    const [nav, setNav] = useState(false);
    const [hint1, setHint1] = useState(false);
    const [hint2, setHint2] = useState(false);
    const [currentTileMaxPoint, setcurrentTileMaxPoint] = useState('')
    const [userAnswer, setUserAnswer] = useState('');
    const [guessState, setGuessState] = useState(false);
    const [guessText, setGuessText] = useState('');
    const [currentTile, setCurrentTile] = useState(1);
    const [currentTileMiniat, setCurrentTileMiniat] = useState('');
    const [currentTileIndice1, setCurrentTileIndice1] = useState('');
    const [currentTileIndice2, setCurrentTileIndice2] = useState('');
    const [showAnswer, setShowAnswer] = useState(false);
    const [currentMessage, setCurrentMessage] = useState('')
    const [currentTitle, setCurrentTitle] = useState('')
    const [displayHint2, setdisplayHint2] = useState(false)
    const [highScore, setHighScore] = useState(0);
    const [highScoreUser, setHighScoreUser] = useState('');
  
    const [currentUsername, setCurrentUsername] = useState('')
    const [currentEmail, setCurrentEmail] = useState('')
  
    const [userScore, setUserScore] = useState(0);


  const [seen, setSeen] = useState(true)

  function togglePop() {
    setSeen(!seen);
  };

  const handleCloseGuess = () => {
    setNav(false);
    setHint1(false);
    setHint2(false);
    setUserAnswer('')
  };
  const closeDialogMessage = () => {
    setDialogMessage(false)
  }
  const openDialogMessage = () => {
    setDialogMessage(true)
  }
  
  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") 
    return;
    handleDisplay(false);
}

  const openDialogBox = () => {
    handleDisplay(true);
  };

  const dialogStyle = {
    padding: "20px",
  };
  const buttonStyle = {
    width: "10rem",
    fontsize: "1.5rem",
    height: "2rem",
    padding: "5px",
    borderRadius: "10px",
    backgroundColor: "green",
    color: "White",
    border: "2px solid yellow",
  };

  const polyStyle = {
    opacity: 0
  }

  const polyStyleWin = {
    fillOpacity: 0.7,
    Opacity: 0.7,
    stroke: "black",
    strokeWidth: 5,
    strokeOpacity: 0.7,
    paintOrder: 'stroke',
  }

  function togglePop() {
    setSeen(!seen);
  };

  function closeTimeOut() {
    setTimeout(() => handleCloseGuess(), 2000)
  };
  function closeMessageTimeOut(timer) {
    setTimeout(() => closeDialogMessage(), timer)
  };

  return (
    <div>
      {seen ?
        <Dialog onClose={handleClose} open={openDialog}>
          <DialogTitle style={{ fontSize: 28 }}>Wall of Game </DialogTitle>
          <div style={dialogStyle}>
            <p style={{ fontSize: 30 }}>
              Problème en cours de résolution Wall Of Game sera bientôt de retour
            </p>
          </div>
        </Dialog>
        : null}
        {dialogMessage ?
        <Dialog onClose={closeDialogMessage} open={openDialogMessage}>
          <DialogTitle>{currentTitle}</DialogTitle>
          <div style={dialogStyle}>
            <p style={{ fontSize: 14 }}>
              {currentMessage}
            </p>
          </div>
        </Dialog>
        : null}
      <Navbar />
      <div>
        <h1 style={{ color: 'white' }}>Mon score : 0</h1>
      </div>
      <div className='gameboard-container'>
        <svg className='svg-gameboard' version="1.1" xmlns="http://www.w3.org/2000/svg" width="656" height="3000" viewBox="0 0 656 3000">
        </svg>
      </div>
      <Footer />
    </div>
  );
}

export default Maintenance;
