import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Gameboard from './components/gameboard/Gameboard'
// import SignIn from './components/login/SignIn'
// import Inauguration from './components/inauguration/Inauguration';
import Maintenance from './components/maintenance/Maintenance'

const App = () => {
  return (
    <Router>
	<Routes>
	    <Route exact path="/" element={<Gameboard />} />
      <Route path="/wog" element={<Gameboard />} />
      <Route path="/maintenance" element={<Maintenance />} />
      </Routes>
    </Router>
  );
};

export default App;





