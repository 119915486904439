import { React, useState, useEffect } from 'react';
//import axios from 'axios'
import styles from './Navbar.module.css';
import Logo from '../../images/logo.png';
import { Button } from "../button/Button";

import {
  AiOutlineUser,
  AiOutlineMenu,
  AiOutlineClose,
} from 'react-icons/ai';



const Navbar = () => {
  const [debug, setdebug] = useState(false);
  
  const [resolution, setResolution] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });


  const [nav, setNav] = useState(false);
  const [highScore, setHighScore] = useState(0);
  const [highScoreUser, setHighScoreUser] = useState('');


  
  const getHighScore = () => {
    fetch('https://wallofgame-apiimperiale.parthenay.fr/high-score', {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then(response => response.json())
      .then(data => {
        setHighScoreUser(data.username)
        setHighScore(data.high_score)
        //console.log("Highscore updated")
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const handleSignOut = (e)  => {
    localStorage.removeItem('username');
    localStorage.removeItem('email');
    localStorage.removeItem('userid');
    window.location.reload();
  }
  useEffect(() => {
    getHighScore();
    // if(localStorage.getItem('userid') == 736){
    //   setdebug(true);
    // }
  
    let interval = setInterval(() => {
      getHighScore()}, 10000);
    
      const handleResize = () => {
        setResolution({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
  
      // Ajouter un écouteur d'événement pour détecter les changements de taille d'écran
      window.addEventListener('resize', handleResize);
  
      // Nettoyer l'écouteur d'événement lors du démontage du composant
      return () => {
        clearInterval(interval);
        window.removeEventListener('resize', handleResize);
      };
    //   return () => {
    //   clearInterval(interval);
    // };



    
  }, []);

  return (
    <header className={styles.navbar}>
      <nav>
        <ul className={nav ? [styles.menu, styles.active].join(' ') : [styles.menu]} >
        <li>
            <AiOutlineUser size={25} style={{ marginTop: '6px' }} />
          </li>
          <li>
            <a>Meilleur score : {highScoreUser} [{highScore} pts]</a>
          </li>
          { localStorage.getItem('username') ? <li>
          <Button variant="contained"
                          onClick={
                              (e) => handleSignOut(e)
                      }>
                          Déconnection
                      </Button>
          </li>:null}
          { localStorage.getItem('username') ? 
          <li>
          login : {localStorage.getItem('username')}{ localStorage.getItem('email') == "wog-citedesjeux@parthenay.fr" ? null : " - " + localStorage.getItem('email')}
          </li>
          :null}
          
          {debug?<li>

            Largeur : {resolution.width}px  - Hauteur : {resolution.height}px
          </li>:null}
        </ul>
      </nav>
      <div onClick={()=> setNav(!nav)} className={styles.mobile_btn}>
        {nav ? <AiOutlineClose size={30} /> : <AiOutlineMenu size={30} />}
        
      </div>
      <a href='https://www.parthenay.fr/cite-des-jeux' target="_blank" ><img className={styles.image} style={{marginTop:80}} src={Logo} alt='Logo' height={140} width={100}/></a>
    </header>
  );
};

export default Navbar;
