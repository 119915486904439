import React from 'react'
import Logo from '../../images/logo_foot.png'

import styles from './Footer.module.css'

const Footer = () => {
  return (
    <div className={styles.footer}>
        <div className={styles.container}>
          <div>
        <a href="https://www.parthenay.fr/cite-des-jeux" target="_blank" ><img className='footLogo' src={Logo} alt="" height={80} width={225}/></a>
        </div><div>
            <a href="https://www.parthenay.fr/cite-des-jeux" target="_blank" >© Cité des jeux de la ville de Parthenay</a><br /><a>contact : </a><a href="mailto:citedesjeux@parthenay.fr">citedesjeux@parthenay.fr</a>
        </div>
        </div>
    </div>
  )
}

export default Footer